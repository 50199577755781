import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { MedicaoMonitoramento } from '../../../model/controles/medicao-monitoramento/medicao-monitoramento';
import { MedicaoMonitoramentoResultado } from '../../../model/controles/medicao-monitoramento/medicao-monitoramento-resultado';

@Injectable({
  providedIn: 'root'
})
export class MedicaoMonitoramentoService {

  private url = `${environment.url}/controles/medicao-monitoramento`

  constructor(
    private httpClient: HttpClient
  ) { }


  public salvarMedicaoMonitoramento(medicaoMonitoramento: MedicaoMonitoramento): Observable<MedicaoMonitoramento> {
    return this.httpClient.post<MedicaoMonitoramento>(`${this.url}`, medicaoMonitoramento)
  }
  public editarMedicaoMonitoramento(medicaoMonitoramento: MedicaoMonitoramento): Observable<MedicaoMonitoramento> {
    return this.httpClient.put<MedicaoMonitoramento>(`${this.url}`, medicaoMonitoramento)
  }

  public incluirMedicaoMonitoramentoCalibracao(medicaoMonitoramento: MedicaoMonitoramentoResultado, idMedicao: number): Observable<MedicaoMonitoramentoResultado> {
    return this.httpClient.post<MedicaoMonitoramentoResultado>(`${this.url}/resultados/${idMedicao}`, medicaoMonitoramento)
  }
  public editarCalibracao(medicaoMonitoramento: MedicaoMonitoramentoResultado,idMedicao:number): Observable<MedicaoMonitoramentoResultado> {
    return this.httpClient.put<MedicaoMonitoramentoResultado>(`${this.url}/resultado/${idMedicao}`, medicaoMonitoramento)
  }
  public deletaCalabricao(id: number, idMed: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.url}/resultado/${id}/${idMed}`);
  }

  public consultaPaginadaMedicaoMonitoramento(paginaAtual: number, quantidadePorPagina: number): Observable<MedicaoMonitoramento[]> {
    return this.httpClient.get<MedicaoMonitoramento[]>(`${this.url}/${paginaAtual}/${quantidadePorPagina}`)
  }
  public consultaPorIdMedicaoMonitoramento(id: number): Observable<MedicaoMonitoramento> {
    return this.httpClient.get<MedicaoMonitoramento>(`${this.url}/${id}`);
  }

  public consultaRecursosDisponiveis(): Observable<MedicaoMonitoramento[]> {
    return this.httpClient.get<MedicaoMonitoramento[]>(`${this.url}/recursos`);
  }
  public consultaRecursosDescalibrados(): Observable<MedicaoMonitoramento[]> {
    return this.httpClient.get<MedicaoMonitoramento[]>(`${this.url}/recursos`);
  }


}
